// import jsDoc types for IntelliSense
import '../../types/index.d';
import '../../types/integration.d'

import { fetchJson, getUrlParams } from './helpers';
import { getProp } from './getProp'
import { getPageMetaData } from './get-page-meta-data';
import { log, isDebugMode, isTraceMode } from '../log';
import { normalizeIntegrationId } from './normalize-id';
import { trackAPIMethods } from '../tracking';

let configsPromise = false;

export const hasPlaceHolders = url => {
	return url.includes('{') && url.includes('}');
};

export const replacePlaceHolders = (integrationUrl, configFields) => {
	let url = integrationUrl;
	Object.keys(configFields).forEach(field => {
		if (field !== 'integrationUrl' && url.includes(`{${field}}`)) {
			const replacer = new RegExp(`{${field}}`, 'g');
			url = url.replace(replacer, configFields[field]);
		}
	});
	return url;
};

/**
 *
 * @returns { Promise<Configs> }
 */
export const loadConfigs = async () => {
	if (!configsPromise) {
		const pageData = await getPageMetaData();
		const { layoutType, pageName, siteId, locale } = pageData;
		const uri = '/api/ipp/config/services';
		const integrationTypes = ['api', 'api_compatible'];
		const fetchParams = [
			`integrationType=${integrationTypes.join(',')}`,
			`deviceType=${layoutType}`,
			`pageAlias=${pageName}`,
			`siteId=${siteId}`,
			`locale=${locale}`
		];
		configsPromise = fetchJson(
			{},
			`${uri}?${fetchParams.join('&')}`,
			'loadConfigs'
		);
	}
	return configsPromise;
};

export const processLegacyConfigData = async init => {
	const { settings } = window.DDC.Integration;

	Object.keys(settings).forEach(configKey => {
		if (normalizeIntegrationId(configKey) !== configKey) {
			settings[normalizeIntegrationId(configKey)] = settings[configKey];
			delete settings[configKey];
		}
	});

	if (settings[init.integrationId] !== undefined) {
		trackAPIMethods(init, {
			methodType: 'getConfigDeprecated',
			status: 'Success'
		});
		return settings[init.integrationId];
	}
	return false;
};

export const processConfigData = async (
	init,
	configs,
	testConfig,
	hasLegacyConfigs,
	trackToNewRelic = true
) => {
	const hasTestConfig =
		typeof testConfig === 'object' && (isDebugMode() || isTraceMode());
	const urlParams = getUrlParams();

	// Reject if no config found for this integration
	if (!configs[init.integrationId]) {
		if (hasTestConfig) {
			return testConfig;
		}

		if (trackToNewRelic) {
			trackAPIMethods(init, {
				methodType: 'getConfig',
				status: 'Not Found'
			});
		}

		const availableKeys = hasLegacyConfigs
			? [
					...Object.keys(configs),
					...Object.keys(window.DDC.Integration.settings)
			  ]
			: Object.keys(configs);

		const availableIntegrations = availableKeys.length
			? `Integration configs are available only for the following -> ${[
					...new Set(availableKeys)
			  ].join(', ')}`
			: '';

		throw new Error(
			`Config for ${init.integrationId} not found. ${availableIntegrations}`
		);
	} else {
		if (hasTestConfig && urlParams._integrationConfig === 'override') {
			return testConfig;
		}

		const config = configs[init.integrationId].fields;

		const pageData = await getPageMetaData();

		const { layoutType } = pageData;

		// All integrations created in WISE would return a config obj only
		// when they are enabled on a given page. So, we can skip the device
		// type enablement check for them.
		if (!configs[init.integrationId].wiseIntegration) {
			// Reject if disabled for current device type
			const mobileEnabled =
				layoutType === 'mobile' && config.enabledSeamless === true;

			const desktopEnabled =
				layoutType === 'desktop' && config.enabledDesktop === true;

			if (!(mobileEnabled || desktopEnabled)) {
				if (trackToNewRelic) {
					trackAPIMethods(init, {
						methodType: 'getConfig',
						status: `Disabled for ${layoutType}`
					});
				}
				throw new Error(
					`Config for ${init.integrationId} found but not enabled for the current device type.`
				);
			}
		}

		// No need to include these fields for the consumer.
		const fieldsToExclude = [
			'includedPages',
			'excludedPages',
			'thirdPartyServiceDebug'
		];

		const overrideConfigPageTypes = {
			srp: 'searchPage',
			vdp: 'detailPage',
			index: 'indexPage'
		};

		const resolvedConfig = Object.keys(config)
			.filter(
				key =>
					!fieldsToExclude.includes(key) &&
					!key.startsWith('enabled') &&
					!key.startsWith('addTo')
			)
			.reduce((obj, key) => {
				const newObj = obj;

				// Allow specific config overrides for index, SRP and VDP pages.
				if (key.split('-').length > 1) {
					const [baseKey, targetPage] = key.split('-');
					if (pageData[overrideConfigPageTypes[targetPage]]) {
						if (isTraceMode()) {
							log(
								init.integrationId,
								`Using override key '${key}' to set field '${baseKey}' to '${config[key]}' for '${overrideConfigPageTypes[targetPage]}'.`
							);
						}
						newObj[baseKey] = config[key];
					}

					// Normal case for populating the config object values.
				} else if (!(key in newObj)) {
					newObj[key] = config[key];
				}

				return newObj;
			}, {});

		// Success
		if (trackToNewRelic) {
			trackAPIMethods(init, {
				methodType: 'getConfig',
				status: 'Success'
			});
		}

		return resolvedConfig;
	}
};

const getConfigProp = async (init, methodName, keys) => {
	const configs = await loadConfigs();

	trackAPIMethods(init, {
		methodType: methodName,
		status: 'Success'
	});

	const data = getProp([init.integrationId, ...keys], configs);
	return data === null ? {} : data;
};

export const getContentMappings = async init => {
	return getConfigProp(init, 'getContentMappings', ['contentMappings']);
};

export const getSiteProperties = async init => {
	return getConfigProp(init, 'getSiteProperties', [
		'supplementaryData',
		'siteProperties'
	]);
};

export const getSitemap = async init => {
	return getConfigProp(init, 'getSitemap', ['supplementaryData', 'siteMaps']);
};

export const getConfig = async (
	init,
	testConfig = false,
	trackToNewRelic = true
) => {
	// Check for v1 integration configs in the page markup.
	const hasLegacyConfigs = window?.DDC?.Integration?.settings;

	if (hasLegacyConfigs) {
		const legacyConfigData = await processLegacyConfigData(init);
		if (legacyConfigData !== false) {
			return legacyConfigData;
		}
	}

	try {
		// Check for v2 integration configs from the CMS API endpoint.
		const configs = await loadConfigs();
		const resolvedConfig = await processConfigData(
			init,
			configs,
			testConfig,
			hasLegacyConfigs,
			trackToNewRelic
		);

		// Resolve the config
		if (resolvedConfig) {
			return resolvedConfig;
		}
	} catch (err) {
		if (trackToNewRelic) {
			trackAPIMethods(init, {
				methodType: 'getConfig',
				status: 'Failed',
				message: err
			});
		}
		log(init, `Error calling getConfig. ${err}`);
	}

	return null;
};