import {
	normalizeIntegrationId,
	normalizeIntegrationType
} from '../utils/normalize-id';

import { titleCase } from '../utils/data-formatters';

import { DATA_LOCATION } from '../edit/constants';
import { waitForObject } from '../utils/wait-for';

export const getPixallVisitorId = async () => {
	// Try to get visitorId from 'pxa_id' cookie if it's available.
	const visitorId = window.Cookies.get('pxa_id');
	if (visitorId) {
		return visitorId;
	}

	// Ensure Pixall is available before getting the Visitor ID.
	await waitForObject('Pixall');
	
	// If no cookie, check if window.Pixall is available and retrieve the value from window.Pixall.getVisitorId()
	if (window.Pixall && typeof window.Pixall.getVisitorId !== 'undefined') {
		return window.Pixall.getVisitorId();
	}

	// If still not found, return an error
	return new Error('No Pixall visitorId found');
}

const samplePercent = 5;

const pageInfo = () => {
	const {
		accountId: account = '', // accountId is a NR reserved keyword
		siteId = '',
		defaultDomain = ''
	} = window.DDC.siteSettings;

	const {
		pageInfo: { pageName = '', pagePath = '' },
		attributes: { locale = '' }
	} = window.DDC.dataLayer.page;

	return {
		account,
		siteId,
		defaultDomain,
		pageName,
		pagePath,
		locale
	};
};

export const trackAPIMethods = (init, payload) => {
	if (Math.random() * 100 <= samplePercent) {
		if (window.newrelic) {
			window.newrelic.addPageAction('WebIntegrationEvent', {
				integrationId: normalizeIntegrationId(init.integrationId),
				integrationType: normalizeIntegrationType(init.integrationType),
				integrationCategory: init.integrationCategory || '',
				placesInventoryContent: init.placesInventoryContent || false,
				...payload,
				...pageInfo()
			});
		}
	}
};

export const trackClick = (init, event) => {
	if (!(init || event || window?.DDC?.fireTrackingEvent)) {
		return;
	}

	const { srcElement } = event;

	let targetEl = srcElement;

	if (srcElement.getAttribute('alt') || srcElement.getAttribute('title')) {
		targetEl = srcElement;
	} else if (srcElement.closest('button')) {
		targetEl = srcElement.closest('button');
	} else if (srcElement.closest('a')) {
		targetEl = srcElement.closest('a');
	}

	let dataLocation = targetEl.closest(`[${DATA_LOCATION}]`)
		? targetEl.closest(`[${DATA_LOCATION}]`).getAttribute(DATA_LOCATION)
		: 'Unknown Location';

	// Override page-footer with overlay-content for tracking purposes.
	if (dataLocation === 'page-footer') {
		dataLocation = 'overlay-content';
	}

	const element =
		targetEl.getAttribute('aria-label') ||
		(targetEl.textContent && ['BUTTON', 'A'].includes(targetEl.tagName))
			? targetEl.textContent
			: null ||
			  targetEl.getAttribute('alt') ||
			  targetEl.getAttribute('title') ||
			  'Third Party Content';

	const vehicleUuid = targetEl.closest('.vehicle-card')?.getAttribute('data-uuid');

	const trackingData = {
		event: 'thirdPartyUserEvent',
		product: init.integrationId,
		step: titleCase(dataLocation.split(' ')[0], '-'),
		element,
		action: 'clicked',
		result: 'success',
		vehicleUuid // will be undefined if the clicked element is not within a vehicle card
	};

	window.DDC.fireTrackingEvent(trackingData);
};
