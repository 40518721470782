// import jsDoc types for IntelliSense
import '../../types/editor.d';
import '../../types/index.d';
import {
	activateModules,
	createUniqueId,
	hasDataBusInventory,
	hasLegacyGridViewInventory,
	isVdp,
	hasInventoryData
} from '../utils/helpers';
import { append } from '../utils/append';
import { applyButtonOverrides, applyButtonWrapperOverrides } from '../create/button-markup';
import { getConfig, getContentMappings } from '../utils/load-configs';
import {
	insertLocations,
	updateLocations,
	validateLocationName
} from './locations';
import { log } from '../log';
import { trackAPIMethods } from '../tracking';
import { trackTiming } from '../timings';
import { validateInitIsAnObject } from '../utils/validator';
import { VehicleLocation } from './locations/vehicle-location';
import {
	INSERT,
	INSERT_CTA,
	MODIFY_CTA,
	MODIFY_LINKS,
	RESTRICTIVELY_MODIFY_CTA,
	UPDATE,
	VEHICLE_BADGE,
	VEHICLE_CTAS,
	VEHICLE_MEDIA,
	VEHICLE_PAYMENTS,
	VEHICLE_PRICING
} from './constants';
import { PageLinkLocation } from './locations/page-link';

const allowedCustomInsertLocations = [
	VEHICLE_BADGE,
	VEHICLE_CTAS,
	VEHICLE_MEDIA,
	VEHICLE_PAYMENTS,
	VEHICLE_PRICING
];

export class Editor {
	/**
	 * @param { IntegrationMetaData } init
	 * @param { MethodName } methodName
	 * @param { MethodType } methodType
	 * @param { InsertLocations } location
	 * @param { string } type
	 * @param { Intent } intent
	 * @param { Function } callback
	 */
	constructor(init, methodName, methodType, location, type, intent, callback) {
		const targetLocation = intent || location;

		validateInitIsAnObject(init, 'init');
		validateLocationName(methodType, targetLocation);

		this.uniqueId = createUniqueId();

		this.init = init;
		this.methodType = methodType;
		this.targetLocation = targetLocation;
		this.destinationLocation = null;
		this.defaultLocation = location;

		this.type = type;

		this.integrationCall = callback;

		this.getInsertSite = this.getInsertSite.bind(this);
		this.apply = this.apply.bind(this);

		this.timingKey = `${this.init.integrationId}-${this.targetLocation}`;
	}

	/**
	 * @param { HTMLElement } locationElem
	 * @param {*} locationConfig
	 * @returns { HTMLElement }
	 */
	async getInsertSite(locationElem, locationConfig) {
		// If configured for the location, return the existing location when
		// inserting content so the integration can modify the content rather
		// than placing additional content.
		if (locationConfig?.singleLocation) {
			const queryTargets = [`[data-web-api-id="${this.init.integrationId}"]`];

			if (locationConfig?.placement) {
				queryTargets.push(
					`[data-web-api-placement="${locationConfig.placement}"]`
				);
			}

			const existingSite = locationElem?.querySelector(queryTargets.join(''));

			if (existingSite) {
				return existingSite;
			}
		}

		// Otherwise, create a new location and return it to the integration.
		const elementType = locationConfig?.elementType
			? locationConfig.elementType
			: 'div';

		const insertSite = document.createElement(elementType);
		insertSite.setAttribute('data-web-api-id', this.init.integrationId);

		if (locationConfig?.placement) {
			insertSite.setAttribute(
				'data-web-api-placement',
				locationConfig.placement
			);
		}

		insertSite.setAttribute('data-web-api-unique-id', this.uniqueId);

		let additionalClasses = '';
		let classes = '';

		// Allow locations to have specific styling applied based on a configuration option.
		if (locationConfig) {
			if (locationConfig.style) {
				insertSite.setAttribute('style', locationConfig.style);
			}
			if (locationConfig.responsiveSrpClasses && hasDataBusInventory()) {
				classes = `${locationConfig.responsiveSrpClasses}`;
			} else if (
				locationConfig.gridSrpClasses &&
				hasLegacyGridViewInventory()
			) {
				classes = `${locationConfig.gridSrpClasses}`;
			} else if (locationConfig.vdpClasses && isVdp()) {
				additionalClasses = `${additionalClasses} p-4`;
				if (locationElem?.getAttribute('data-list-classes')) {
					additionalClasses = locationElem?.getAttribute('data-list-classes');
				}
				classes = `${locationConfig.vdpClasses}`;
			} else if (locationConfig.classes) {
				classes = `${locationConfig.classes}`;
			}
			insertSite.setAttribute(
				'class',
				`${classes} ${additionalClasses} hidden`
			);
		}

		// Clear all existing content inside the `data-location` if the location is configured to do so.
		if (locationConfig?.clearContent) {
			while (locationElem?.firstChild) {
				locationElem.removeChild(locationElem.firstChild);
			}
		}

		if (
			locationConfig?.placement &&
			locationConfig?.placement === 'first' &&
			locationElem?.firstChild
		) {
			locationElem.insertBefore(insertSite, locationElem.firstChild);
		} else {
			locationElem?.appendChild(insertSite);
		}

		return insertSite;
	}

	/**
	 * @param { ?{uuid:string} & AnyObject } locationData
	 * @param { ?Boolean } isVehicleEvent
	 * @returns { Promise<void> }
	 */
	async apply(locationData, isVehicleEvent) {
		const start = Date.now();

		const config = await getConfig(this.init, false, false);

		if (
			config?.useCustomInsertLocations &&
			allowedCustomInsertLocations.includes(this.targetLocation) &&
			!this.targetLocation.endsWith('-custom')
		) {
			this.targetLocation = `${this.targetLocation}-custom`;
		}

		// Get the location elements first (which can error)
		// so that errors are propagated to the user right away.
		// Then call the callbacks asynchronously to ensure callers can
		// support it. They'll need to as dynamic content is added to
		// the document.
		let location = null;

		// Handle 'buttons' or other type of mapping
		if (
			this.methodType === MODIFY_CTA ||
			this.methodType === RESTRICTIVELY_MODIFY_CTA
		) {
			// Obtain any applicable content mappings.
			const mappings = await getContentMappings(this.init);
			// Fallback to support the content-mapping schema in tps
			const buttonMappings = mappings.buttons || mappings.button;

			if (buttonMappings) {
				// If the mappings include an entry for this source location,
				// update the location to use the destination target instead.
				Object.entries(buttonMappings).forEach(mapping => {
					const [source, destination] = mapping;
					if (source === this.targetLocation && destination) {
						location = updateLocations[destination]
							? updateLocations[destination]
							: new VehicleLocation(destination);
						this.destinationLocation = destination;
					}
				});
			}

			// Wait for the inventory data to have loaded first
			// Before checking if a CTA location exists on the page
			await hasInventoryData();

			if (
				!location ||
				(location.name !== 'hidden' &&
					!document.querySelector(`[data-location="${location.name}"]`))
			) {
				// If no existing button is targeted, insert a new button instead.
				location = insertLocations[this.defaultLocation];
				this.methodType = INSERT_CTA;
			}
		} else if (this.methodType === INSERT_CTA) {
			location = insertLocations[this.defaultLocation];
		} else if (this.methodType === INSERT) {
			location = insertLocations[this.targetLocation];

			// Currently supporting existing 'update' method.
			// This will be deprecated and removed in a future release.
		} else if (this.methodType === UPDATE) {
			location = updateLocations[this.targetLocation];
		} else if (this.methodType === MODIFY_LINKS) {
			// Obtain any applicable content mappings.
			const linkMappings = (await getContentMappings(this.init)).links || {};
			const currentLinkTargets = linkMappings[this.targetLocation] || [];

			// We check if the WISE schema has the link mapping
			// for the specified target and the links exist on the site
			if (currentLinkTargets && currentLinkTargets.length) {
				// Filter out targets which are currently available in the given page
				const locationTargetsInPage = currentLinkTargets
					.map(target => document.querySelectorAll(`a[href^="${target}"]`))
					.filter(target => target.length)
					.reduce((acc, current) => [...acc, ...current], []);

				if (locationTargetsInPage.length) {
					location = new PageLinkLocation(locationTargetsInPage);
				}
			}
		}

		if (!location) {
			return;
		}

		await location.isReady();

		const locationElems = await location.getElements(locationData);
		const locationConfig = await location.getConfig();

		const callCallbacks = () => {
			if (!locationElems) {
				return;
			}

			locationElems.forEach(async locationElem => {
				try {
					const locationMeta = await location.getMeta(locationElem);

					const shouldInsertItems = (
						isVehicleEvent &&
						locationData &&
						locationData?.uuid === locationMeta?.uuid
					) ? true
					: !locationData;

					const isUnique = locationElem?.querySelectorAll(
						`[data-web-api-unique-id="${this.uniqueId}"]`
					)?.length === 0

					if (!(shouldInsertItems && isUnique)) {
						return;
					}

					await location.prepareLocation(locationElem);

					// Modify or Insert Button
					if (
						this.methodType === INSERT_CTA ||
						this.methodType === MODIFY_CTA ||
						this.methodType === RESTRICTIVELY_MODIFY_CTA ||
						this.methodType === MODIFY_LINKS
					) {
						// Call the integration with the location metadata
						// so it can specify a set of options to override
						// existing button behaviors.
						const options = this.integrationCall(locationMeta);

						// Modify the existing button element in place.
						if (typeof options === 'object') {
							let allowedOptions = options;

							if (
								this.methodType === RESTRICTIVELY_MODIFY_CTA ||
								this.methodType === MODIFY_LINKS
							) {
								const {
									href,
									target,
									onclick,
									popover,
									attributes,
									...restrictedAttrs
								} = options;

								// We only allow the modification of the following
								// attributes for button intents which only allow limited attr to be updated
								allowedOptions = {
									href,
									target,
									onclick,
									popover,
									attributes
								};

								const restrictedAttrsList = Object.keys(restrictedAttrs || {});

								if (restrictedAttrsList.length) {
									const restrictedAttrNames = restrictedAttrsList.join(', ');
									log(
										this.init.integrationId,
										`tried to modify the following button attributes ${restrictedAttrNames} of ${location.name} which is not allowed.`
									);
								}

								const buttonMarkup = applyButtonOverrides(
									this,
									locationElem,
									allowedOptions,
									this.methodType !== MODIFY_LINKS
								);
								activateModules(buttonMarkup);
							} else if (this.methodType === INSERT_CTA) {
								const newLocation = document.createElement('a');
								append(
									this.init,
									await this.getInsertSite(locationElem, locationConfig),
									applyButtonOverrides(
										this,
										newLocation,
										allowedOptions
									)
								);
								activateModules(newLocation);
							} else if (this.methodType === MODIFY_CTA) {
								let newInsertSite;
								const locationIsLink = locationElem.nodeName === 'A';

								// 1. If type of location is an anchor tag, create a wrapper div.
								if (locationIsLink) {
									newInsertSite = await this.getInsertSite(null, locationConfig);
									locationElem.parentNode.appendChild(newInsertSite);

									// 2. Apply the target location attributes to the wrapper div.
									newInsertSite = applyButtonWrapperOverrides(
										this,
										newInsertSite,
										locationElem.getAttribute('data-location')
									);

									// 3. Remove the data-location from the original CTA because it's now on the wrapper div.
									if (locationElem.getAttribute('data-location')) {
										locationElem.removeAttribute('data-location');
									}
								}

								// 4. Still apply the provided CTA options to the original CTA element.
								const updatedButton = applyButtonOverrides(
									this,
									locationIsLink ? locationElem : locationElem.querySelector('a'),
									allowedOptions,
									true, // Is Vehicle CTA
									!locationIsLink // Include WIAPI data attributes only if target is not an anchor tag.
								);

								if (newInsertSite) {
									// 5. Finally, append the updated CTA to the new wrapper div location.
									newInsertSite.appendChild(updatedButton);
								}

								// 6. Activate click handlers for the new content.
								activateModules(updatedButton);
							}
						} else {
							return;
						}
					} else if (this.methodType === INSERT) {
						const shouldRenderAsFirstChild = config?.renderAsFirstChild;
						if (shouldRenderAsFirstChild && location.name === VEHICLE_PRICING) {
							// insert in the first place
							locationConfig.placement = 'first';
						}

						this.integrationCall(
							await this.getInsertSite(locationElem, locationConfig),
							locationMeta
						);

						// Update
					} else if (this.methodType === UPDATE) {
						log(
							'WARNING: The `API.update` method is deprecated and will soon be removed. Please use `API.insertCallToAction` instead. For details, please visit https://dealerdotcom.github.io/web-integration-api-docs/#api-insertcalltoaction-type-intent-setupfunction-meta'
						);
						this.integrationCall(locationElem, locationMeta);
					}

					const closestLocation = locationElem.closest('[data-location]');
					closestLocation?.classList?.remove('hide', 'hidden');

					trackAPIMethods(this.init, {
						methodType: this.methodType,
						status: 'Success',
						locationName: this.targetLocation
					});

					const end = Date.now();
					trackTiming(this.methodType, this.timingKey, end - start);
				} catch (err) {
					const end = Date.now();
					log(
						this.init.integrationId,
						`Failed to edit into ${this.targetLocation} and took ${
							end - start
						}ms to fail... ${err}`
					);
					trackAPIMethods(this.init, {
						methodType: this.methodType,
						status: 'Failed',
						locationName: this.targetLocation,
						message: err
					});
				}
			});
		};
		setTimeout(callCallbacks, 0);
	}
}
